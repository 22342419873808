import { IParsedDocument } from '../pages/documents/[uid]'
import { IAnyPageExclError } from '../types/prismic/pages'
import { INews } from '../types/prismic/pages/news'
import { IImageSlice } from '../components/Slices/ImageSlice'

export interface IHreflang {
	url: string
}

interface ISEOLinks {
	canonical: string
	hreflangs?: IHreflang[]
}

export interface ISEOData {
	title: {
		metaTitle?: string
		ogTitle?: string
		pageTitle?: string
	}
	metaDescription?: string
	links?: ISEOLinks
	noIndex?: boolean
	ogDescription?: string
	ogImage?: string
}

const buildFrontpageSeoLinks = (): ISEOLinks => {
	return { hreflangs: [], canonical: '/' } //TODO update link resolving methods used here.
}

const buildPageSeoLinks = (currentPage: { uid: string }): ISEOLinks => {
	return { hreflangs: [], canonical: `/${currentPage.uid}` }
}
/*
const buildSubpageSeoLinks = (currentPage: { uid: string; data: { link_to_parent: { uid?: string } } }): ISEOLinks => {
  const { data, uid } = currentPage
  return { hreflangs: [], canonical: `/${data.link_to_parent.uid}/${uid}` }
}
*/

export const getPageSEOData = (page: IAnyPageExclError): ISEOData => {
	return {
		title: {
			metaTitle: page.data.meta_title,
			ogTitle: page.data.og_title,
			pageTitle: page.data.title,
		},
		metaDescription: page.data.meta_description,
		links: page.uid ? buildPageSeoLinks({ uid: page.uid }) : undefined,
		noIndex: page.data.no_index,
		ogImage: page.data.og_image?.url,
		ogDescription: page.data.og_description,
	}
}

export const getDocumentPageSEOData = (page: IParsedDocument): ISEOData => {
	return {
		title: {
			metaTitle: page.data.title,
			ogTitle: page.data.title,
			pageTitle: page.data.title,
		},
		links: page.uid ? buildPageSeoLinks({ uid: page.uid }) : undefined,
	}
}
/*
export const getSubPageSEOData = (subpage: ISubPage.IProps): ISEOData => {
  return {
	metaTitle: subpage.data.meta_title,
	metaDescription: subpage.data.meta_description,
	links: buildSubpageSeoLinks({
	  ...subpage,
	  data: {
		...subpage.data,
		link_to_parent: 'link_to_parent' in subpage.data ? subpage.data.link_to_parent : { uid: routingPaths.news },
	  },
	}), //I reckon we need a better solution to this
	noIndex: subpage.data.noIndex,
	ogImage: subpage.data.og_image?.url,
	ogTitle: subpage.data.og_title,
	ogDescription: subpage.data.og_description,
  }
}
*/

export const getNewsArticleSEOData = (
	newsArticlePage: INews.IArticle
): ISEOData => {
	const imageSlice: IImageSlice | undefined = newsArticlePage.data.body?.find(item => item.slice_type === "image");

	return {
		title: {
			metaTitle: newsArticlePage.data.meta_title || newsArticlePage.data.title,
			ogTitle: newsArticlePage.data.og_title || newsArticlePage.data.title,
		},
		links: newsArticlePage.data.links, // ? not used
		metaDescription:
			newsArticlePage.data.meta_description ||
			newsArticlePage.data.short_description,
		noIndex: newsArticlePage.data.no_index,
		ogImage: imageSlice?.primary.image.url ?? newsArticlePage.data.og_image?.url,
		ogDescription:
			newsArticlePage.data.og_description ||
			newsArticlePage.data.meta_description ||
			newsArticlePage.data.short_description,
	}
}
export const getFrontPageSEOData = (): ISEOData => {
	return {
		links: buildFrontpageSeoLinks(),
		noIndex: false,
		title: {},
	}
}
