import Head from 'next/head'

import { defaultSeoData } from '../../constants/DefaultSeoData'
import { ISEOData } from '../../utils/seoHelpers'

export const baseUrl =
	process.env.VERCEL_ENV === 'production'
		? process.env.NEXT_PUBLIC_SITE_URL || 'www.kvika.is'
		: process.env.VERCEL_URL ||
		  process.env.NEXT_PUBLIC_SITE_URL ||
		  'umsokn.kvika.is'

export const NextHeader = (seoData: ISEOData) => {
	const defaultOgValues = defaultSeoData.is
	const ogTitle =
		seoData.title.ogTitle != undefined && seoData.title.ogTitle.length > 0
			? seoData.title.ogTitle
			: defaultOgValues.title
	const ogDescription =
		seoData.ogDescription != undefined && seoData.ogDescription.length > 0
			? seoData.ogDescription
			: defaultOgValues.description

	const ogImage =
		seoData.ogImage != undefined && seoData.ogImage.length > 0
			? seoData.ogImage
			: defaultOgValues.image

	return (
		<Head>
			<title>{`Kvika ${
				seoData.title.metaTitle
					? `- ${seoData.title.metaTitle}`
					: `- ${seoData.title.pageTitle}`
			}`}</title>
			<meta charSet='utf-8' />
			<meta name='viewport' content='initial-scale=1.0, width=device-width' />
			{seoData.noIndex && <meta name='robots' content='noindex' />}
			{seoData.metaDescription && (
				<meta name='description' content={seoData.metaDescription} />
			)}
			<meta property='og:title' content={ogTitle} />
			<meta property='og:description' content={ogDescription} />
			<meta property='og:image' content={ogImage} />

			{/* Twitter */}
			<meta property='twitter:card' content='summary_large_image' />
			<meta property='twitter:title' content={ogTitle} />
			<meta property='twitter:description' content={ogDescription} />
			<meta property='twitter:image' content={ogImage} />
			<meta name='theme-color' content='#b89e74' />
			<meta name='msapplication-TileColor' content='#b89e74' />
		</Head>
	)
}

export default NextHeader
